import { DateTime } from 'luxon';
import { isEmpty, isFinite } from 'lodash';
import {
  Mission,
  MissionCategoryKind,
  MissionKind,
  MissionOrderItem,
} from './Mission';
import { MissionSegmentToCreate } from './MissionSegment';
import { getMissionTemplate } from '../../utilities/Mission';
import { getMissionSegmentTemplate } from '../../utilities/MissionSegment';
import { MissionRewardTierType } from './MissionReward';
import { sha256 } from '../../utilities/Crypto';
import { MissionMetricType } from './MissionMetric';

export enum MissionUploadState {
  DRAFT = 'DRAFT',
  UPLOADING_MISSION = 'UPLOADING_MISSION',
  UPLOAD_MISSION_FAILED = 'UPLOAD_MISSION_FAILED',
  UPLOADING_SEGMENT = 'UPLOADING_SEGMENT',
  UPLOAD_SEGMENT_FAILED = 'UPLOAD_SEGMENT_FAILED',
  ROLLED_BACK = 'ROLLED_BACK',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  DONE = 'DONE',
}

export interface MissionToUpload extends Mission {
  segmentsToCreate: MissionSegmentToCreate[];
  state: MissionUploadState;
  errorMessage: string | null;
}

const missionFromCsvRow = (csvRow: string[]): Mission => {
  const mission = getMissionTemplate();

  const availableKinds = Object.keys(MissionCategoryKind).map((key) =>
    MissionCategoryKind[key as keyof typeof MissionCategoryKind].toString(),
  );

  const missionCategoryKind = csvRow[0].toLowerCase();
  if (!availableKinds.includes(missionCategoryKind)) {
    throw new Error(
      `Unknown type '${missionCategoryKind}'. Available types are ${JSON.stringify(
        availableKinds,
      )}`,
    );
  }

  mission.title = csvRow[1];
  if (mission.title.length === 0) {
    throw new Error('Mission name cannot be empty');
  }

  mission.description = csvRow[2].length === 0 ? undefined : csvRow[2];

  mission.startAtLocal = DateTime.fromFormat(
    csvRow[3].toUpperCase(),
    'MM/dd/yyyy h:mm a',
  ).toJSDate();
  mission.endAtLocal =
    csvRow[4].length === 0
      ? undefined
      : DateTime.fromFormat(
          csvRow[4].toUpperCase(),
          'MM/dd/yyyy h:mm a',
        ).toJSDate();

  mission.requirements.locations = csvRow[5]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => ({ id: id.trim() }));

  mission.requirements.metros = csvRow[6]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => ({ id: id.trim() }));

  const countryCode = csvRow[7].toUpperCase();
  if (countryCode === 'US') {
    mission.requirements.country = countryCode;
  } else if (countryCode === 'GB' || countryCode === 'UK') {
    mission.requirements.country = 'GB';
  } else if (countryCode.length === 0) {
    mission.requirements.country = undefined;
  } else {
    throw new Error(
      `Unsupported eligible country '${countryCode}'. Supported countries are ['US','UK','GB']`,
    );
  }

  if (
    !mission.requirements.country &&
    isEmpty(mission.requirements.metros) &&
    isEmpty(mission.requirements.locations)
  ) {
    throw new Error(
      'At least one eligible MFC ID, Metro ID or Country should be specified',
    );
  }
  if (
    mission.requirements.country &&
    (!isEmpty(mission.requirements.metros) ||
      !isEmpty(mission.requirements.locations))
  ) {
    throw new Error(
      'Eligible MFC IDs or Metro IDs cannot be specified when a country is specified',
    );
  }

  const alcoholOnlyOption = csvRow[8].toUpperCase();
  if (alcoholOnlyOption === 'Y') {
    mission.requirements.ordersMustContain.push(MissionOrderItem.ALCOHOL);
  } else if (alcoholOnlyOption !== 'N' && alcoholOnlyOption !== '') {
    throw new Error(
      `Cannot determine Applicable to Alcohol Deliveries Only option. Expected 'Y', 'N' or empty cell, found '${alcoholOnlyOption}'`,
    );
  }

  if (csvRow[9].length === 0) {
    mission.maxDriverCount = undefined;
  } else {
    mission.maxDriverCount = parseInt(csvRow[9], 10);
    if (!isFinite(mission.maxDriverCount)) {
      throw new Error(
        `Could not parse Max DP Count. '${csvRow[9]}' is not an integer`,
      );
    }
  }

  if (
    missionCategoryKind === MissionCategoryKind.PER_ORDER_BOOST ||
    missionCategoryKind === MissionCategoryKind.PER_TRIP_BOOST
  ) {
    mission.reward.pay = parseFloat(csvRow[10]);
    if (!isFinite(mission.reward.pay)) {
      throw new Error(
        `Could not parse Reward. '${csvRow[10]}' is not a number`,
      );
    }

    mission.type =
      missionCategoryKind === MissionCategoryKind.PER_ORDER_BOOST
        ? MissionKind.PER_ORDER_BOOST
        : MissionKind.PER_TRIP_BOOST;
  } else {
    const rewardParts = csvRow[10].split(';').map((part) => part.trim());
    if (rewardParts.length % 2 === 0 || rewardParts.length < 3) {
      throw new Error(
        'Could not parse Reward. Expected format is type;tier_1_count;tier_1_pay;tier_2_count;tier_2_pay;...tier_n_count;tier_n_pay',
      );
    }

    const availableTierTypes = Object.keys(MissionRewardTierType).map((key) =>
      MissionRewardTierType[
        key as keyof typeof MissionRewardTierType
      ].toString(),
    );
    const tierType = rewardParts[0].toLowerCase();
    if (!availableTierTypes.includes(tierType)) {
      throw new Error(
        `Unknown tier type '${tierType}'. Available tier types are ${JSON.stringify(
          availableTierTypes,
        )}`,
      );
    }
    mission.reward.tierType = tierType as MissionRewardTierType;
    if (tierType === MissionRewardTierType.ORDER) {
      mission.type = MissionKind.ORDER_BONUS;
    } else {
      mission.type = MissionKind.TRIP_BONUS;
    }

    for (let i = 1; i < rewardParts.length; i += 2) {
      const count = parseInt(rewardParts[i], 10);
      if (!isFinite(count)) {
        throw new Error(
          `Cannot parse tier ${Math.ceil(i / 2).toString()} count. '${
            rewardParts[i]
          }' is not an integer.`,
        );
      }
      const pay = parseFloat(rewardParts[i + 1]);
      if (!isFinite(pay)) {
        throw new Error(
          `Cannot parse tier ${Math.ceil(i / 2).toString()} pay. '${
            rewardParts[i + 1]
          }' is not a number.`,
        );
      }

      mission.reward.tiers.push({ count, pay });
    }
  }

  return mission;
};

const missionToUploadFromCsvRow = (csvRow: string[]): MissionToUpload => {
  const expectedColumns = 32;

  if (csvRow.length !== expectedColumns) {
    throw new Error(
      `Expected ${expectedColumns} columns, found ${csvRow.length.toString()}. ${
        csvRow.length > expectedColumns
          ? 'Please make sure there are no commas in the cell values.'
          : ''
      }`,
    );
  }

  let missionToUpload: MissionToUpload = {
    ...getMissionTemplate(),
    segmentsToCreate: [],
    state: MissionUploadState.DRAFT,
    errorMessage: null,
  };

  if (csvRow.slice(0, 5).filter((cell) => isEmpty(cell)).length < 5) {
    missionToUpload = {
      ...missionToUpload,
      ...missionFromCsvRow(csvRow),
      idempotencyKey: sha256(csvRow.join(',')),
    };
  }

  const segmentToCreate = getMissionSegmentTemplate(false);

  segmentToCreate.name = csvRow[11];
  if (segmentToCreate.name.length === 0) {
    throw new Error('Segment name cannot be empty');
  }

  segmentToCreate.locationIDs = csvRow[12]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => id.trim());

  segmentToCreate.metroIDs = csvRow[13]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => id.trim());

  const segmentCountryCode = csvRow[14].toUpperCase();
  if (segmentCountryCode === 'US') {
    segmentToCreate.countryCode = segmentCountryCode;
  } else if (segmentCountryCode === 'GB' || segmentCountryCode === 'UK') {
    segmentToCreate.countryCode = 'GB';
  } else if (segmentCountryCode.length === 0) {
    segmentToCreate.countryCode = undefined;
  } else {
    throw new Error(
      `Unsupported segment country '${segmentCountryCode}'. Supported countries are ['US','UK','GB']`,
    );
  }

  segmentToCreate.driverIDs = csvRow[15]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => id.trim());

  const completedFirstDeliveryOption = csvRow[16].toUpperCase();
  if (completedFirstDeliveryOption === 'Y') {
    segmentToCreate.completedFirstDelivery = true;
  } else if (completedFirstDeliveryOption === 'N') {
    segmentToCreate.completedFirstDelivery = false;
  } else if (!isEmpty(completedFirstDeliveryOption)) {
    throw new Error(
      `Cannot determine Completed First Delivery option. Expected 'Y', 'N' or empty, found '${completedFirstDeliveryOption}'`,
    );
  }

  const acceptanceRate = Math.floor(parseFloat(csvRow[17]));
  if (isFinite(acceptanceRate)) {
    segmentToCreate.acceptanceRate = {
      operator: '>=',
      amount: acceptanceRate,
    };
  }

  const completionRate = Math.floor(parseFloat(csvRow[18]));
  if (isFinite(completionRate)) {
    segmentToCreate.completionRate = {
      operator: '>=',
      amount: completionRate,
    };
  }

  const onTimeRate = Math.floor(parseFloat(csvRow[19]));
  if (isFinite(onTimeRate)) {
    segmentToCreate.onTimeRate = {
      operator: '>=',
      amount: onTimeRate,
    };
  }

  const dnrRate = Math.floor(parseFloat(csvRow[20]));
  if (isFinite(dnrRate)) {
    segmentToCreate.dnrRate = {
      operator: '<=',
      amount: dnrRate,
    };
  }

  const scheduledAvailabilityRate = Math.floor(parseFloat(csvRow[21]));
  if (isFinite(scheduledAvailabilityRate)) {
    segmentToCreate.scheduledAvailabilityRate = {
      operator: '>=',
      amount: scheduledAvailabilityRate,
    };
  }

  const completedOrderCount = Math.floor(parseFloat(csvRow[22]));
  if (isFinite(completedOrderCount)) {
    segmentToCreate.completedOrderCount = {
      operator: '>=',
      amount: completedOrderCount,
    };
  }

  const compositeScore = Math.floor(parseFloat(csvRow[23]));
  if (isFinite(compositeScore)) {
    segmentToCreate.compositeScore = {
      operator: '>=',
      amount: compositeScore,
    };
  }

  segmentToCreate.mustBeActiveAt = csvRow[24]
    .split(';')
    .filter((id) => id.trim().length > 0)
    .map((id) => id.trim());

  if (
    isEmpty(segmentToCreate.locationIDs) &&
    isEmpty(segmentToCreate.metroIDs) &&
    !segmentToCreate.countryCode &&
    isEmpty(segmentToCreate.driverIDs) &&
    isEmpty(segmentToCreate.mustBeActiveAt)
  ) {
    throw new Error(
      'At least 1 Segment MFC ID, Metro ID, Country or DP ID should be specified',
    );
  }

  segmentToCreate.notActiveSince =
    csvRow[25].length === 0
      ? undefined
      : DateTime.fromFormat(
          csvRow[25].toUpperCase(),
          'MM/dd/yyyy h:mm a',
        ).toJSDate();

  const stratificationByMetroOption = csvRow[26].toUpperCase();
  if (stratificationByMetroOption === 'Y') {
    segmentToCreate.experimentation.stratification.byMetro = true;
  } else if (
    stratificationByMetroOption === 'N' ||
    stratificationByMetroOption === ''
  ) {
    segmentToCreate.experimentation.stratification.byMetro = false;
  } else {
    throw new Error(
      `Cannot determine Stratify by Metro option. Expected 'Y', 'N' or empty cell, found '${stratificationByMetroOption}'`,
    );
  }

  try {
    segmentToCreate.experimentation.stratification.byDriverHours = csvRow[27]
      .trim()
      .split(';')
      .filter((range) => !isEmpty(range.trim()))
      .map((range) => ({
        min: parseInt(range.split('-')[0].trim(), 10),
        max: parseInt(range.split('-')[1].trim(), 10),
      }));
  } catch (err: any) {
    throw new Error(
      `Could not parse Stratify by Driver Hours field: ${
        err?.message ?? 'unknown error'
      }`,
    );
  }

  segmentToCreate.experimentation.isExperiment =
    segmentToCreate.experimentation.stratification.byMetro ||
    !isEmpty(segmentToCreate.experimentation.stratification.byDriverHours);

  const alreadyActiveDriverOption = csvRow[28].toUpperCase();
  if (alreadyActiveDriverOption === 'Y') {
    segmentToCreate.includeAlreadyActiveDrivers = true;
  } else if (alreadyActiveDriverOption === 'N') {
    segmentToCreate.includeAlreadyActiveDrivers = false;
  } else {
    throw new Error(
      `Cannot determine Include Already Active Drivers option. Expected 'Y' or 'N', found '${alreadyActiveDriverOption}'`,
    );
  }
  if (
    !isEmpty(segmentToCreate.mustBeActiveAt) &&
    !segmentToCreate.includeAlreadyActiveDrivers
  ) {
    throw new Error(
      'Include Already Active Drivers option should be set as Y if Must Be Active At is not empty',
    );
  }

  const sendPushNotificationOption = csvRow[29].toUpperCase();
  if (sendPushNotificationOption === 'Y') {
    segmentToCreate.sendPushNotifications = true;
  } else if (sendPushNotificationOption === 'N') {
    segmentToCreate.sendPushNotifications = false;
  } else {
    throw new Error(
      `Cannot determine Send Push Notification option. Expected 'Y' or 'N', found '${sendPushNotificationOption}'`,
    );
  }

  const autoAcceptInvitationsOption = csvRow[30].toUpperCase();
  if (autoAcceptInvitationsOption === 'Y') {
    segmentToCreate.autoAcceptInvitations = true;
  } else if (autoAcceptInvitationsOption === 'N') {
    segmentToCreate.autoAcceptInvitations = false;
  } else {
    throw new Error(
      `Cannot determine Automatically Accept Invitations option. Expected 'Y' or 'N', found '${autoAcceptInvitationsOption}'`,
    );
  }

  const acceptanceRateMetric = Math.floor(parseFloat(csvRow[31]));
  if (isFinite(acceptanceRateMetric)) {
    missionToUpload.requirements.metrics[MissionMetricType.ACCEPTANCE] = {
      operator: '>=',
      amount: acceptanceRateMetric,
    };
  }

  missionToUpload.segmentsToCreate.push(segmentToCreate);

  return missionToUpload;
};

export const parseMissionsToUpload = (
  csvRows: string[][],
): MissionToUpload[] => {
  const missionsToUpload: MissionToUpload[] = [];
  let lastMissionIndex = 0;

  for (let i = 0; i < csvRows.length; i += 1) {
    const row = csvRows[i];
    if (
      i === 0 &&
      row.slice(0, 5).filter((cell) => isEmpty(cell)).length === 5
    ) {
      continue;
    }

    let missionToUpload: MissionToUpload;
    try {
      missionToUpload = missionToUploadFromCsvRow(row);
    } catch (err: any) {
      throw new Error(
        `Could not parse given CSV file. Error in line ${(
          i + 1
        ).toString()}. Reason: ${err.message}`,
      );
    }

    if (missionToUpload.idempotencyKey) {
      missionsToUpload.push(missionToUpload);
      lastMissionIndex = i;
    } else {
      if (i === 0) {
        throw new Error('Cannot create a segment before creating a mission');
      }

      missionsToUpload[lastMissionIndex].segmentsToCreate.push(
        missionToUpload.segmentsToCreate[0],
      );
    }
  }

  return missionsToUpload;
};
