import { flatten } from 'lodash';
import { CsvTemplate } from '../interfaces/CsvTemplate';

export const adjustmentsCsvTemplate: CsvTemplate = {
  name: 'AdjustmentsTemplate',
  content:
    'Adjustment Type,Driver ID,Amount,Workweek,Notes,Text Input,Date Input\n' +
    'Missing Tip,9999,12,08/22/2022,example note,,\n' +
    'Missing Order,9998,$40.01,07/11/2022,,,07/11/2022\n' +
    'Missing Boost,9997,100.01,08/29/2022,,example text input,',
};

export const goMissionsCsvTemplate: CsvTemplate = {
  name: 'GoMissionsTemplate',
  content:
    'Incentive Type,Mission Name,Mission Description,Start At,End At,Eligible MFC IDs,Eligible Metro IDs,Eligible Country,Applicable to Alcohol Deliveries Only (Y/N),Max DP Count,Reward,Segment Name,Recently Active At MFC IDs,Recently Active At Metro IDs,Recently Active At Country Code,Segment DP IDs,Completed First Delivery (Y/N/Empty),Trip Offer Acceptance Rate (>=),Completion Rate (>=),On Time Rate (>=),DNR Rate (<=),Scheduled Availability Rate (>=),Completed Order Count (>=),Composite Score (>=),DP Must Be Active At Location IDs,Not Active Since,Stratify by Metro (Y/N/Empty),Stratify by Driver Hours,Include Already Active Drivers (Y/N),Send Push Notification (Y/N),Automatically Accept Invitations (Y/N),Acceptance Rate Completion Criteria (>=)\n' +
    ',,,,,,,,,,,,Only DPs who have an active workday or a workday that has finished within the last 6 weeks will be included.,Only DPs who have an active workday or a workday that has finished within the last 6 weeks will be included.,Only DPs who have an active workday or a workday that has finished within the last 6 weeks will be included.,,Only DPs who have / have not completed an order will be included. The order may be from any MFC.,Only DPs who have the required trip acceptance rate in the previous workweek will be included. The rate is aggregated from trips at all MFCs.,Only DPs who have the required completion rate in the previous workweek will be included. The rate is aggregated from all MFCs.,Only DPs who have the required on time rate in the previous workweek will be included. The rate is aggregated from all MFCs.,Only DPs who have the required DNR rate in the previous workweek will be included. The rate is aggregated from all MFCs.,Only DPs who have the required scheduled availability rate in the previous workweek will be included. The rate is aggregated from all MFCs.,Only DPs who have completed the required number of orders in the previous workweek will be included. The orders may be from any MFC.,,Only DPs who have an active workday at the chosen MFCs will be included.,Only DPs who have not had a workday at any of the mission MFCs within the chosen time frame will be included. They may have had a workday at a non-mission MFC within the timeframe.,,,DPs who currently have an active workday at any MFC (even non-mission MFCs) will be excluded from the mission.,,,DPs that has higher acceptance rate then required during the mission will be rewarded.\n' +
    'per_order_boost,Example Per Order Boost,Some Description,07/11/2022 11:15 PM,07/12/2022 6:15 AM,803;358;837,,,Y,5,3.5,Example Segment,358;837,,,,,50,60,70,55,75,20,75,,,,,Y,N,N,\n' +
    'per_order_boost,Another Example Per Order Boost,Some Description,07/11/2022 11:15 PM,,,M0001;M0002,,N,,0.25,Example Segment,,M0001,,,,,,,,,,,,,,,N,Y,Y,\n' +
    'per_order_boost,Mission for Orders (ALL UK),,07/12/2022 6:15 AM,07/12/2022 8:15 AM,,,UK,N,25,3.14,First Segment,,,,123456;7891011,,80,,66,74,,25,80,,07/11/2022 6:15 AM,,,Y,Y,N,\n' +
    'per_order_boost,Mission for Orders Again,This time for all US,07/12/2022 6:15 AM,,,,US,Y,250,5,First Segment,,,US,,,,,,,,,,135;140,,,,Y,N,Y,\n' +
    'do_x_get_y,Tiered Mission for Orders (ALL UK),,07/12/2022 6:15 AM,07/12/2022 8:15 AM,,,UK,N,25,order;1;3.14;2;5.25;3;10.75,First Segment,,,,123456;7891011,,,,,,,,,35;780,,,,Y,N,N,\n' +
    'do_x_get_y,Acceptance Rate Missions,,07/12/2022 6:15 AM,07/12/2022 8:15 AM,,,UK,N,25,order;3;10.75,First Segment,,,,123456;7891011,,,,,,,,,35;780,,,,Y,N,N,80\n' +
    'per_order_boost,Experiment Mission 1,Stratified by Metro,07/12/2022 6:15 AM,,,,US,N,250,5,First Segment,,,US,,,,,,,,,,135;140,,Y,,Y,N,Y,\n' +
    'per_order_boost,Experiment Mission 2,Stratified by Driver Hours (Single Range),07/12/2022 6:15 AM,,,,US,Y,250,5,First Segment,,,US,,Y,,,,,,,,135;140,,N,1-10,Y,N,Y,\n' +
    'per_order_boost,Experiment Mission 2,Stratified by Driver Hours (Multiple Ranges),07/12/2022 6:15 AM,,,,US,N,250,5,First Segment,,,US,,N,,,,,,,,135;140,,N,1-10;15-25;25-35,Y,N,Y,\n',
};

export const modalityCsvTemplate: CsvTemplate = {
  name: 'ModalityTemplate',
  content:
    'LocationID,LocationName,Modality,CompletedOrderCount\n' +
    '192,TEST_MFC_192,bike,26021\n' +
    '296,TEST_MFC_296,car,26021\n' +
    '839,JFK_New-York_839,bike,26021\n' +
    '869,JFK_New-York_869,car,21737\n',
};

export const restrictedAccountCsvTemplate: CsvTemplate = {
  name: 'RestrictedAccountTemplate',
  content:
    'Driver ID,Restriction Type\n' +
    '18,Garnishment\n' +
    '19,3rd Party Labor\n',
};

export const settingsCsvTemplate: CsvTemplate = {
  name: 'PipelineSettingsTemplate',
  content:
    'Location ID,Start Date,Target P90 Delivery Time (Minutes),Target P90 Delivery Driver Controllable Time (Minutes),Max Delta Orders Per Driver Hour,Max Delta Commission,Time Rate Lower Bound,Time Rate Upper Bound,Minimum Time Rate Missing Data,DCPO Neutral Over Week (Y/N),No Commission Increase (Y/N)\n' +
    '192,07/11/2022,57,50,0.1,0.2,0.1,0.45,0.2,N,N\n' +
    '803,07/12/2022,57,50,0.1,0.2,0.1,0.45,0.2,Y,Y',
};

export const targetCommissionCsvTemplate: CsvTemplate = {
  name: 'TargetCommissionTemplate',
  content:
    'MFC ID,Weekday Commission,Weekend Commission\n' +
    '100,0.28,\n' +
    '101,0.27,0.40\n' +
    '102,0.25,0.3\n' +
    '103,0.25,0.28\n' +
    '104,0.26,0.29\n' +
    '105,0.27,0.31\n',
};

export const timeRatesCsvTemplate: CsvTemplate = {
  name: 'TimeRatesTemplate',
  content:
    'LocationId,TimeBlock,TimeRate,Modality,Strategy\n' +
    '191,0,0.2801,bike,default\n' +
    '191,1,0.2701,bike,default\n' +
    '191,2,0.2501,bike,default\n' +
    '191,3,0.2501,bike,default\n' +
    '191,4,0.2601,bike,default\n' +
    '191,5,0.2701,bike,default\n' +
    '192,0,0.2801,bike,\n' +
    '192,1,0.2701,bike,\n' +
    '192,2,0.2501,bike,\n' +
    '192,3,0.2501,bike,\n' +
    '192,4,0.2601,bike,\n' +
    '192,5,0.2701,bike,\n' +
    '193,0,0.2801,,\n' +
    '193,1,0.2701,,\n' +
    '193,2,0.2501,,\n' +
    '193,3,0.2501,,\n' +
    '193,4,0.2601,,\n' +
    '193,5,0.2701,,',
};

export const waitPayRatesTemplate: CsvTemplate = {
  name: 'WaitPayRatesTemplate',
  content: `Location ID,${flatten(
    [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ].map((day) =>
      ['Morning', 'Midday', 'Peak', 'Late night'].map(
        (period) => `${day} ${period}`,
      ),
    ),
  ).join(',')}\n${Array(3)
    .fill(0)
    .map(
      (_, i) =>
        `${((i + 1) * 220).toString()},${Array(28)
          .fill(0)
          .map((_2) => ((Math.floor(Math.random() * 5) + 1) * 0.1).toFixed(2))
          .join(',')}`,
    )
    .join('\n')}`,
};
